// This is an absolute mess need to clean it up. 


// ------------------------------------------------------------------- //
// Menu
// ------------------------------------------------------------------- //

.mobile-nav {
	float: right;
  #menu {
      padding-top: 120px;
      > li, .login > li {
        display: block;
        text-align: left;
        line-height: 1;
        a {
        	color: white;
        }
      }
  }
}
nav.navigation.mobile-nav {
	.social {
		li {
			display: inline-block;
		}
	}
}

#menu {
  position: fixed;
  right: 0px;
  height: 100vh;
  top: 0px;
  bottom: 0px;
  padding: 160px 32px  54px 32px;
  height: 100vh;
  background: $primary;
  list-style-type: none;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  width: 40%;
  li {
    padding: 10px 0;
    font-size: 22px;
  }
  .social {
    margin-top: 60px;
  }
  @include phone {
    width: calc(100vw - 0px);
  }
}

#menuToggle input:checked ~ ul {
  transform: scale(1, 1);
  opacity: 1;
}



#menuToggle {
	display: inline-block;
	position: relative;
  z-index: 999;
	top: 8px;
	width: 28px;
	margin-left: 60px;
  @include phone {
    top: 12px;
    right: -3px;
  }
  input {
    display: inline-block;
    width: 48px;
    height: 40px;
    position: absolute;
    top: -10px;
    left: -10px;
    cursor: pointer;
    opacity: 0;
    z-index: 999;
    padding: 5px;
    z-index: 300;
    -webkit-touch-callout: none;
  }
  span:not(.login) {
    display: block;
    width: 26px;
    height: 3px;
    margin-bottom: 4px;
    position: relative;
    background: white;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
  input:checked ~ span:not(.login) {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -0px);
    background: $highlight;
    &:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:nth-last-child(2) {
      opacity: 1;
      transform: rotate(-45deg) translate(0, -2px);
    }
  }
}



// ------------------------------------------------------------------- //
// Menu
// ------------------------------------------------------------------- //


header {
  nav {
    &.top-menu {
      > ul {
        li {
          display: inline;
        }
      }
    }
    .main-menu {
       ul {
        li {
          display: block;
          line-height: 1;
          padding: 0px !important;
          &:first-of-type {
            &:after {
              content: '';
              display: initial;
              padding: initial;
              transform: initial;
              color: initial;
            }
          }
        }
      }
    }
  }

  li.hide-mobile {
    &:first-of-type {
      &:after {
        content: '|';
        display: inline-block;
        padding: 0px 10px 0px 15px;
        transform: scale(1, 1.5);
        color: lighten($grey,50%);
      }
    }
  }

  .social {
  	li {
  		display: inline-block;
  	}
    a {
      color: $highlight !important;
      font-size: 18px;
      margin-right: 10px;
      &:hover {
      	border-bottom: none;
      }
    }
  }

  .secondary-menu {

    li {
      display: block;
      line-height: 1;
      padding: 0px;
      a {
        display: block;
        color: $highlight !important;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

}

#menuToggle {
  .main-menu, .menu-item, .social, .secondary-menu {
    opacity: 1;
    transition: opacity 2s ease;
  }
  &.menu-open {
    .main-menu, .menu-item, .social, .secondary-menu {
      opacity: 1;
      animation: 0.8s ease-in 0s normal forwards 1 fadein;
    }
    #menu {
        box-shadow: 0 0 20px 0 rgba(50,50,50,0.2);
    }
  }
}

@keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

li.parent {
  &:first-of-type {
    margin-bottom: 40px;
  }
}

#menu a {
  border-bottom: 0px;
}
// ------------------------------------------------------------------- //
// Body
// ------------------------------------------------------------------- //

html, body {
	height: 100%;
}
body {
	display: flex;
	flex-direction: column;
}
main {
	flex: 1 0 auto;
}

// ------------------------------------------------------------------- //
// Header
// ------------------------------------------------------------------- //

body > header {
	height: auto;
	position: fixed;
	z-index: 999;
	width: 100%;
	top:0;
	nav {
		ul li {
			display: inline-block;
		}
		// @include phone {
		// 	display: none;
		// }

		@media only screen and (min-width: 0px) and (max-width: 873px) {
			display:none;
		}
	}
}

.logo {
	height: 40px;
	@include phone {
		position: relative;
		z-index: 1000;
	}
}

.left {
	@include phone {
		padding-left:0;
	}
}

.right {
	@include phone {
		padding-right:0;
	}
}
// ------------------------------------------------------------------- //
// Menu
// ------------------------------------------------------------------- //


nav.navigation {
	ul {
		float: right;
		@include phone {
			// float: left;
		}
		li {
			@include prop(padding-left, 15px,15px,15px,10px,15px);
			@include prop(padding-right, 0px,15px,15px,10px,15px);
			@include prop(line-height, 40px,40px,40px,42px,42px);
			text-align: right;
		}
	}
	span.login {
		@include prop(margin-left, 60px, 60px, 60px, 60px, 100px);
	}
}

// ------------------------------------------------------------------- //
// Footer
// ------------------------------------------------------------------- //

footer {
	@include prop(padding-top, 30px, 40px, 50px, 60px, 60px);
	@include prop(padding-bottom, 30px, 40px, 50px, 60px, 60px);
	ul {
		li {
			display: inline;
			margin-right: 10px;
		}	
	}
	.social {
		text-align: right;
		li {
			margin-right: 0px;
			margin-left: 9px;
		}
	}
}


// ------------------------------------------------------------------- //
// Articles
// ------------------------------------------------------------------- //

// article {
// 	padding-top: 122px;

// 	.text {
// 		@include prop(padding, 30px, 20px, 20px, 20px, 40px);
// 		@include prop(padding-top, 0px, 0px, 0px, 0px, 0px);			
// 	}
// }

// ------------------------------------------------------------------- //
// Type Positioning
// ------------------------------------------------------------------- //

// Vertical Positioning of Title


// ------------------------------------------------------------------- //
// Hero Illustration
// ------------------------------------------------------------------- //



.ill {
	position: absolute;
	width: 100%;
	top: calc(50% + 0px);
	// top: 50%;
    transform: translateY(-50%);
    z-index: 300;
    @include prop(padding-left, 20px, 0px, 0px, 0px, 0px);
	@include prop(padding-right, 20px, 0px, 0px, 0px, 0px);
    p {
    	max-width: 500px;
    	margin: 0 auto;
    }
	img {
		// @include prop(max-height, 180px, 400px, 400px, 450px, 500px);
		max-height: 50vh;
		height: 100%;
		width: auto;
		@include phone {
			max-height: 35vh;
			height: auto !important;
			width: auto !important;
			// margin:0 auto;
			margin:0 auto;
		}
	}
}


.banner-content {

    @include prop(padding-top, 60px, 60px, 50px, 60px, 60px);
	@include prop(padding-bottom, 60px, 60px, 60px, 60px, 60px);

	img {
		max-height: 50vh;
		@include phone {
			max-height: 40vh;
		}		
	}

	.pricing & {
		// @include prop(padding-bottom, 0px, 0px, 0px, 0px, 0px);
	}
}


// ------------------------------------------------------------------- //
// Images
// ------------------------------------------------------------------- //


.feature {
	.icon {
		display: inline-block;
	}
	.feature-title {
		.icon {
			float: left;
			margin: -5px 30px 0 0; 
		}
		&.centered {
			.icon {
				float: inherit;
				display: inline-block;
				margin: 0px 0px 20px 0; 
			}
		}
	}

	.feature-text:last-child {
		// background:red;
		@include laptop {
			// flex:0 0 100% !important;
			// max-width: 70%;
			// text-align:center;
			// margin: 0 auto;
		}
	}
}

h2 {
	span.icon {
		top: -5px;
		position: relative;
		margin-right: 20px !important;
	}
}

.why-us {
	.feature {
		@include prop(margin-bottom, 60px, 60px, 60px, 90px, 90px);
	}
}


// ------------------------------------------------------------------- //
// Slider
// ------------------------------------------------------------------- //

.home-hero {
	.hero-title {
		margin-bottom: 30px;
	}
}


.feature-hero, .story-hero {
	// height: 100vh;
	padding-top: 100px;
}

.home-hero {
	.owl-stage {
		height: calc(100vh - 0px);
	}

	.owl-carousel .owl-stage-outer {
		overflow: hidden;
	}

	.owl-item {
		position: relative;
		height: calc(100vh - 0px);
	}
	.hero-title {
		position: relative;
		top: 40%;
	    transform: translateY(calc(-50%));
	}
	.item {
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
	 }

	.owl-dots {
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translate(-50%,0%);
		margin: 0 auto;
		@include phone {
			bottom: 5px;
		}	
	}

	.owl-theme .owl-dots, .owl-theme .owl-nav {
	    text-align: center;
	}
	.owl-dot {
		height: 12px;
		width: 12px;
		display: inline-block;
		margin: 2px;
	}
	// owl resize fix
	.owl-carousel  .owl-wrapper,
	.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	}

}

.hero-title {
	position: absolute;
	top: 45%;
	width: 100%;
    transform: translateY(calc(-50%));
    z-index: 200;
}


// ------------------------------------------------------------------- //
// Features
// ------------------------------------------------------------------- //

section {

	&.features {
		@include prop(padding-top, 60px, 60px, 60px, 60px, 60px);
		@include prop(padding-bottom, 60px, 60px, 60px, 60px, 60px);
	}
	&.pricing {
		@include prop(padding-top, 60px, 60px, 60px, 60px, 60px);
		@include prop(padding-bottom, 60px, 60px, 60px, 60px, 60px);
	}
	&.story {
		@include prop(padding-top, 60px, 60px, 60px, 60px, 60px);
		@include prop(padding-bottom, 60px, 60px, 60px, 60px, 60px);
	}
}

.pricing-cards {
	justify-content: center;
	align-items: stretch;
	
	@include prop(padding-top, 10px, 40px, 20px, 60px, 60px);
	@include prop(padding-bottom, 10px, 40px, 20px, 60px, 60px);

	@media only screen and (min-width: 768px) {
		.card {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}

	@media all and (-ms-high-contrast:none) {
    	.card { min-height: 650px; }
    }

	.points {
		flex: 1 0;

		@include laptop {
			min-height:191px;
		}

		ul li {
			line-height: 3;
			// min-height: 55px;
			display: flex;
			flex-direction: column;
			justify-content: center;	
			
			border-bottom: 1px solid #eee;
		}
	}
}


.feature {

	@include prop(padding-top, 30px, 60px, 60px, 60px, 60px);
	@include prop(padding-bottom, 30px, 60px, 60px, 60px, 60px);


	&:last-child {
		padding-bottom: 0px;
	}
	
	.feature-img {
		text-align: center;
	}

	&:nth-child(even) {
		.feature-img {
			order: 2;
			@media (max-width: 425px) { order:1; }
		}
		.feature-text {
			order: 1;
		}
	}
}

.dev-form {
	@include prop(padding-top, 10px, 10px, 10px, 60px, 60px);
	@include prop(padding-bottom, 30px, 30px, 30px, 40px, 40px);

	.container {
		// padding:0;
		// width:100%;
	}

}

section.dev-form {
		margin-top: -115px;
		position: relative;
		z-index: 999;
}

// ------------------------------------------------------------------- //
// Form
// ------------------------------------------------------------------- //

.form-wrapper {
 	display: flex;
 	align-items: center;
 	height: 100%;
 }
// ------------------------------------------------------------------- //
// Split
// ------------------------------------------------------------------- //



.split {
	position: relative;;
	height: 100vh;


	.full {
		position: absolute;
		height: 100vh;	
		width: 50%;
		top: 0px;
		bottom: 0px;
		background: $grad;
		&.right {
			right: 0px;
		}
		&.left {
			left: 0px;
		}
		.color-overlay {
			position: absolute;
			height: 100vh;	
			width: 100%;
			top: 0px;
			bottom: 0px;
			right: 0px;
			background: rgba(30,50,240,0.2);

			@include phone {
				position: relative !important;
				height: auto !important;
			}
		}
	}

}


section.features {
	padding-bottom: 0px;
	img {
		max-height: 550px;
	}

}

.pricing {
	.small {
		margin-top: 30px;
	}

	h2.hero {
		@include tablet {
			// padding-top:80px;
		}
	}
}

.pricing-cards {

	@media only screen and (min-width:0px) and (max-width: 767px) {
		flex-direction: column !important;
		display:block;
		text-align:Center;

		.second {
			.card {
				margin: 20px 0;

				@include phone {
					margin:20px 0vw;
				}
			}
		}
	}

	.card {
		@include phone {
			margin:0 0vw;
		}
	}
}

.pricing-feature {
	@include phone {
		margin:0 5vw;
	}
}


.hero {
	&.pricing {
		padding-top: 15vh;

		@media only screen and (min-width:0px) and (max-width: 991px) {
			padding-top:100px;
		}
	}
}

section.story {

	.feature {
		display: flex;
	}

	&:nth-child(odd) {
		.feature {
			// flex-direction: row-reverse; 
		}
	}

}

.story-hero {
	height: 90vh;
}

.align-right {
// @include prop(padding-left, 0, 0, 0, 0, 0);
// padding-left: 20px;
// padding-right: calc((100vw - 1400px) / 2);
// @include prop(padding-right, 0, calc((100vw - 700px) / 2), calc((100vw - 700px) / 2), 10vw, 12vw);
}

.align-left {
// @include prop(padding-right, 0, 0, 0, 0, 0);
// padding-left: calc((100vw - 1400px) / 2);
// @include prop(padding-left, 15px,15px,15px,10px,15px);
// @include prop(padding-left, 0, calc((100vw - 700px) / 2), calc((100vw - 700px) / 2), 10vw, 12vw);
}

.justify-content-center {
	align-items: center;
}

.profile.feature {
	padding-bottom: 0px;
}

.profile img {
	height: 400px;

		@media only screen and (min-width:0px) and (max-width: 500px) {
			height:auto;
		}
}

.feature-img {
	.bg-image {
		@media (max-width: 768px) { 
			margin:30px 0;
		}
	}
}

.feature-img img {
	max-width: 450px;
}


section.features, section.story {
	.container-fluid {
		> div:nth-child(odd) {
				.align-right {
				    // @include prop(padding-right, 0, 0, 0, 30px, 0);
				    // @include prop(padding-left, 0, 0, 0, 5vw, 8vw);
				}

				.align-left {
				    // @include prop(padding-left, 0, 0, 0, 30px, 0);
				    // @include prop(padding-right, 0, 0, 0, 5vw, 8vw);

				}
		}
	}
}


.feature-hero {
	img {
		width: 100%;
		border-radius: 5px;
	}
}

.pricing {
	.feature-text {
		img {
			height: 120px;
			width: 120px;

		}
	}
}

.profiles {
	padding: 60px 10vw 0px 10vw;
	@include desktop {
		// padding: 60px 1vw 0px 1vw;
	}

		.row {
			@include phone {
				margin-left:0;
				margin-right:0;
			}
		}
	@include phone {
		padding: 30px 0px;
	}
}

#stories .profiles {
	@include tablet {
		.display {
			flex-direction:column;

			.col {
				flex:0 0 100%;
				max-width:100%;
			}
		}
	}
}

.why-us .profile, .home .profile {
	padding: 35px;
	@include desktop {
		padding: 30px;	
	}
	@include tablet {
		padding:5vh 20vw;
	}
	@include phone {
		padding:3vh 10vw;
		flex:0 0 100%;
	}
	img {
		max-width: 200px;
		max-height: 200px;
	}

	p.lead {
		// max-width:50%;
		margin: 0 auto;
		@include phone {
			padding:10px 10px;
		}
	}
}

.pricing .feature {
	    padding-bottom: 0px;
}


.sign-up-single {

	@include super {
		margin-left:10vw;
		margin-right:10vw;
		margin-top:8vw;
		margin-bottom:8vw
	}
	@include phone {
		padding: 30px 20px;
	}

	.signup-content {
		width: 80%;
		margin: 0 auto;
	}
}

.mobile-signup {

    .ill img {
        max-width: 50vw;
    }

	@media only screen and (min-width: 0px) and (max-width: 800px) {

        .ill img {
            max-width: 100vw;
        }
        
		& > div {
			position:relative !important;
			width: 100% !important;
			display:block !important;
			height:80vh !important;
			background: none !important;
		}

		& > div:last-child {
			margin-top:-50px;
			margin-bottom:60px;
			height:auto !important;
			// height: 200px !important;
		}
	}
}

.remove {
		padding-left:0 !important;
		padding-right:0 !important;
		margin-left:0 !important;
		margin-right:0 !important;
}

.price-title {
	@include desktop {
		height:80px;
	}
}

body.features,
body.experiences,
body.virtuals,
body.schedules {

	
.feature {
	max-width: 1400px;
	margin: 0 auto;

	a {
		display:inline-block;
	}
}

@include super {
	.feature-text, .feature-img {
		padding: 0px 60px;
	}
	p {
		font-size: 24px;
	}
	.trigger-forgot p, .trigger-forgot a {
		font-size: 15px;	
	}
}

}

section.features.story {
	.feature {
		max-width: 1400px;
		margin: 0 auto;

		a {
			display:inline-block;
		}	
	}
}

// ------------------------------------------------------------------- //
// PRIVACY
// ------------------------------------------------------------------- //

.privacy-content {
	// margin-top:200px;
	padding-top: 170px;

	p {
		
	}

	hr {
		    border-top: 1px solid white;
    		margin: 20px 0 10px 0;
    		padding: 0;
	}
}


// ------------------------------------------------------------------- //
// CONTACT
// ------------------------------------------------------------------- //


section.contact {
	@include prop(padding-top, 180px, 180px, 180px, 220px, 280px);
	padding-bottom:12vh;

	textarea {
		// width:100%;
	}

	.contact-methods {
		margin: 20px 0 40px;
	}
	
	.contact-method {
		margin-bottom: 16px;
		i {
			min-width: 50px;
			font-size: 20px;
		}
	}

	.card.form.contact {
		max-width:600px;
		margin: 0 auto;

		@media only screen and (max-width: 1000px) { 
			max-width: 100%;
			// margin:0 15vw;
		}

	}

	p.contact-email, a.address > p {
		padding-top: 10px;
	}

}

.contact-info {
	padding-top: 30px;
	@include phone {
		padding-top: 0px;
	}
}

.contact-title {
	max-width: 560px;
	margin: 0 auto;
}

div.thankyou {
	padding:15vw 10vw 35vw 10vw;

	@include phone {
		// padding-top:150px;
		// padding-bottom:40vh;
		padding:150px 20px 35vh 20px;
	}
}

.contact-map {
	display:flex;	
	background: white;
	flex-direction:row;

	margin:0 auto;

	max-width: 950px;

	@media only screen and (max-width: 1000px) { 
		flex-direction:column;
		margin:0 15vw;
	}
	
	@media only screen and (max-width: 1000px) { 
		margin:0 10%;
	}

	@media only screen and (max-width: 768px) { 
		margin:0 5%;
	}

	@include phone {
		margin:0 -15px;
	}


	& > div {
		padding:0;
	}
}

.contact.card.form {
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
}

.contact-form {
	@media only screen and (max-width: 1000px) { 
		max-width:100%;
		flex:1;
	}
}
.map-container {
	position:relative;
	overflow:hidden;
	height:480px;

	@media only screen and (max-width: 1000px) { 
		flex:1;
		max-width:100%;
		height:400px;
	}

	#map {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.profile.contact-title {
	img {
		max-height:140px;
	}

	// padding-left:31vw;
	// padding-right:31vw;
	margin-bottom:6vw;
	margin-top:-5%;

	// @media only screen and (max-width: 1000px) { 
	// 	padding-left:20vw;
	// 	padding-right:20vw;
	// }
}

.contact-info {
	// margin: 2% 15vw 0 15vw !important;

	margin:0 auto;

	max-width: 950px;
	// height:200px;

	@media only screen and (max-width: 1000px) { 
		margin: 0 15vw;
	}
	
	@include phone {
		margin: 0 0;
	}

	.contact-email {
		padding-bottom:0;

		a {
			padding-bottom:1px;
			border-bottom:1px solid transparent;
			color: white;
		}
	}

	.contact-phone {
		padding-top:0;

		a {
			padding-bottom:1px;
			border-bottom:1px solid transparent;
			color: white;
		}
	}

	.contact-address {

		a {
			padding-bottom:1px;
			border-bottom:1px solid transparent;
			color: white;
		}
	}

	.contact-email, .contact-phone, .contact-address {
		a:hover {
			color: #FEF86D;
		}
	}
}
// ------------------------------------------------------------------- //
// BLOG
// ------------------------------------------------------------------- //

section.blog {
	@include laptop {
		margin:0 10vw;
	}

	@include desktop {
        margin:0 15vw;
	}

	@include super {
        margin:0 auto;
        max-width: 1200px;
        width: 100%;
	}
	.title {
		margin-top:150px;
		margin-left:16vw;
		margin-right:16vw;
		text-align:center;

		@include desktop {
			margin-left:10vw;
			margin-right:10vw;
			margin-top:180px;
			margin-bottom:100px;
		}

		@include super {
			margin-left:10vw;
			margin-right:10vw;
			margin-top:180px;
			margin-bottom:100px;
		}
	}

	// article:first-of-type {
	// 	margin: 50px 0px 0 0px;
	// }

	article {
		// background:white;
		padding-top:0;
		margin:60px 0px;
		padding:30px 60px;

		@include phone {
			margin:60px 10px;
		}

		@include super {
			margin:70px 0;
		}

		.article-header {

			@include desktop {
				position:relative;
			}

			@include super {
				position:relative;
			}

			h2 {
				a {
					color: $blue;
					border-bottom:0;
					padding-bottom:20px;
					display:block;

					@include desktop {
						max-width:80%;
					}
				}
			}

			p {
				color:$blue;
				padding:0;
				padding-bottom:20px;

				@include desktop {
					padding-bottom:0;
					position:absolute;
					top:10px;
					right:0;
				}

				@include super {
					padding-bottom:0;
					position:absolute;
					top:10px;
					right:0;
				}
			}
		}


		.blog-posts {
			p {
				padding:0;
			}
		}

		.blog-content {
			p {
				padding:0;
			}

			p:nth-child(n+2) {
				padding-top:30px;
			}
		}

		.article-more {
			padding-top:10px;
			a.post {
				margin-top:15px;
			}
		}
	}
}

.single-post.blog {
	margin-top:150px;

    .article-title {
        h2 {
            margin: 20px 0;
        }
    }

	.blog-image {
		min-height:200px;
		position:relative;

		img {
            max-width: 100%;
        }
	}

	.blog {
		article {
			margin:0 auto 70px;
            position:relative;
            max-width: 800px;

			.article-date {
				position:relative;
				padding-bottom:20px;
			}

			figure {
				text-align: center;
				margin: 2em 0;

				img {
					max-width: 100%;
				}
			}
		}
	}
}

.pagination a.left {
	margin-right:20px;
}

nav.pagination {
	@include phone {
		margin:0 auto;
	}
}


// ------------------------------------------------------------------- //
// Project Variables
// ------------------------------------------------------------------- //

$primary:        rgba(0,29,149,1);
$highlight:     rgba(8,239,183,1);
$secondary:      rgba(254,55,106,1);

$light-blue:   rgba(204,210,232,1);
$white:          rgba(255,255,255,1);


// Media Query Breakpoints
$xs: 480px; $sm: 768px;  $md: 1024px; $lg: 1440px;  $xl: 1440px;  
$phone: 480px; $tablet: 768px;  $laptop: 1024px; $desktop: 1440px;  $super: 1600px;  

$family: "Metric", "Helvetica Neue", Helvetica, Arial, sans-serif;

// ------------------------------------------------------------------- //
// Break-points
// ------------------------------------------------------------------- //

@mixin xs { @media (max-width: $xs) { @content; } }
@mixin sm { @media only screen and (min-width: $sm){   @content; } }
@mixin md { @media only screen and (min-width: $md) {   @content; } }
@mixin lg { @media only screen and (min-width: $lg) {   @content; } }
@mixin xl { @media only screen and (min-width: $xl) {   @content; } }


@mixin from($from, $to) {
  @media only screen and (min-width: $from) and (max-width: $to) {
    @content;
  }
}


@mixin only($size) {
  @if $size == $phone {
    @media only screen and (max-width: $phone) {
      @content;
    }
  } @else if $size == $tablet {
    @media only screen and (min-width: $phone) and (max-width: $laptop - 1px) {
      @content;
    }
  } @else if $size == $laptop {
    @media only screen and (min-width: $laptop) and (max-width: $desktop - 1px) {
      @content;
    }
  } @else if $size == $desktop {
    @media only screen and (min-width: $desktop) and (max-width: $super - 1px) {
      @content;
    }
  } @else if $size == $super {
    @media only screen and (min-width: $super) {
      @content;
    }
  }
}


body::before {
  position: fixed;
  top: 0px;
  left: 0px;
  display: block;
  background: blue;
  padding: 5px;
  font-size: 12px;
  text-transform: uppercase;
  display: none;
}



@include only($phone) {
  body::before {
    content: 'Phone Only';
  }
}

@include only($tablet) {
  body::before {
    content: 'Tablet Only';
  }
}

@include only($laptop) {
  body::before {
    content: 'Laptop Only';
  }
}


@include only($desktop) {
  body::before {
    content: 'Desktop Only';
  }
}


@include only($super) {
  body::before {
    content: 'Super Only';
  }
}




@include from($md, $xl) {

}



// @include only($tablet) {

// }


// ------------------------------------------------------------------- //
// Dynamic Property Mixin
// ------------------------------------------------------------------- //


@mixin blend($device, $property, $value) {
  @if ($device == tablet ) {
    @include tablet { #{$property} : #{$value}; }
  } @else if ($device == laptop ) {
    @include laptop { #{$property} : #{$value}; }
  } @else if ($device == desktop ) {
    @include desktop { #{$property} : #{$value}; }
  } @else if ($device == super ) {
    @include super { #{$property} : #{$value}; }
  }
}

// ------------------------------------------------------------------- //
// Blend
// ------------------------------------------------------------------- //

@mixin blend($properties, $min-screen, $max-screen, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value); 
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

// Requires the calc-scale function which can also be used independently
@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen; 

  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }
  @return calc(#{$a*100}vw #{$sign} #{$b});
}

// ------------------------------------------------------------------- //
// Grid
// ------------------------------------------------------------------- //



// ------------------------------------------------------------------- //
// Interpolation
// ------------------------------------------------------------------- //

// ----
// Sass (v3.4.21)
// Compass (v1.0.3)
// ----

// Interpolate v1.0

// This mixin generates CSS for interpolation of length properties.
// It has 5 required values, including the target property, initial
// screen size, initial value, final screen size and final value.

// It has two optional values which include an easing property, 
// which is a string, representing a CSS animation-timing-function 
// and finally a number of bending-points, that determines how many 
// interpolations steps are applied along the easing function.

// Author: Mike Riethmuller - @MikeRiethmuller
// More information: http://codepen.io/MadeByMike/pen/a2249946658b139b7625b2a58cf03a65?editors=0100

///
/// @param {String} $property - The CSS property to interpolate
/// @param {Unit} $min-screen - A CSS length unit
/// @param {Unit} $min-value - A CSS length unit
/// @param {Unit} $max-screen - Value to be parsed
/// @param {Unit} $max-value - Value to be parsed
/// @param {String} $easing - Value to be parsed
/// @param {Integer} $bending-points - Value to be parsed
///

// Examples on line 258

// Issues: 

// - cubic-bezier requires whitespace
// - cubic-bezier cannot parse negative values

@mixin interpolate($properties, $min-screen, $min-value, $max-screen, $max-value, $easing: 'linear', $bending-points: 2) {

  // Default Easing 'Linear'
  $p0: 0;
  $p1: 0;
  $p2: 1;
  $p3: 1;

  // Parse Cubic Bezier string
  @if(str-slice($easing, 1, 12) == 'cubic-bezier') {
    // Get the values between the brackets
    // TODO: Deal with different whitespace
    $i: str-index($easing,')'); // Get index of closing bracket
    $values: str-slice($easing, 14, $i - 1); // Extract values between brackts
    $list: explode($values, ', '); // Split the values into a list
    
    // @debug($list);

    // Cast values to numebrs
    $p0: number(nth($list, 1));
    $p1: number(nth($list, 2));
    $p2: number(nth($list, 3));
    $p3: number(nth($list, 4));

  } 

  @if($easing == 'ease') {
    $p0: 0.25;
    $p1: 1;
    $p2: 0.25;
    $p3: 1;
  }

  @if($easing == 'ease-in-out') {
    $p0: 0.42;
    $p1: 0;
    $p2: 0.58;
    $p3: 1;
  }

  @if($easing == 'ease-in') {
    $p0: 0.42;
    $p1: 0;
    $p2: 1;
    $p3: 1;
  }

  @if($easing == 'ease-out') {
    $p0: 0;
    $p1: 0;
    $p2: 0.58;
    $p3: 1;
  }

  @each $property in $properties {
    #{$property}: $min-value;
  }


  @if($easing == 'linear' or $bending-points < 1) { 
    
      @media screen and (min-width: $min-screen) {
        @each $property in $properties {
          #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
        }
      }

  } @else {

    // Loop through bending points  
    $t: 1 / ($bending-points + 1);
    $i:1;
    $prev-screen: $min-screen;
    $prev-value: $min-value;

    @while $t*$i <= 1 {
      $bending-point: $t*$i;
      $value: cubic-bezier($p0,$p1,$p2,$p3, $bending-point);
      $screen-int: lerp($min-screen, $max-screen, $bending-point);  
      $value-int: lerp($min-value, $max-value, $value);

      @media screen and  (min-width: $prev-screen) {
        @each $property in $properties {
          #{$property}: calc-interpolation($prev-screen, $prev-value, $screen-int, $value-int);
        }
      }

      $prev-screen: $screen-int;
      $prev-value: $value-int;
      $i: $i+1;
    }
  }

  @media screen and (min-width:$max-screen) {
    @each $property in $properties {
      #{$property}: $max-value; 
    }
  }

}

// Requires several helper functions including: pow, calc-interpolation, cubic-bezier, number and explode

// Math functions:

// Linear interpolations in CSS as a Sass function
// Author: Mike Riethmuller | https://madebymike.com.au/writing/precise-control-responsive-typography/ I

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen; 

  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }

  @return calc(#{$a*100}vw #{$sign} #{$b});
}

// This is a crude Sass port webkits cubic-bezier function. Looking to simplify this if you can help.
@function solve-bexier-x($p1x, $p1y, $p2x, $p2y, $x) {
  
    $cx: 3.0 * $p1x;
    $bx: 3.0 * ($p2x - $p1x) - $cx;
    $ax: 1.0 - $cx -$bx;
    
    $t0: 0.0;
    $t1: 1.0;
    $t2: $x;
    $x2: 0;
    $res: 1000;
    
    @while ($t0 < $t1 or $break) {
      $x2: (($ax * $t2 + $bx) * $t2 + $cx) * $t2;
      @if (abs($x2 - $x) < $res) {
        @return $t2;
      }
      @if ($x > $x2) {
        $t0: $t2;
      } @else {
        $t1: $t2;
      }
      $t2: ($t1 - $t0) * 0.5 + $t0;
    }

    @return $t2;
}

@function cubic-bezier($p1x, $p1y, $p2x, $p2y, $x) {
  $cy: 3.0 * $p1y;
  $by: 3.0 * ($p2y - $p1y) - $cy;
  $ay: 1.0 - $cy - $by;
  $t: solve-bexier-x($p1x, $p1y, $p2x, $p2y, $x);
  @return (($ay * $t + $by) * $t + $cy) * $t;
}

// A stright up lerp
// Credit: Ancient Greeks possibly Hipparchus of Rhodes
@function lerp($a, $b, $t) {
  @return $a + ($b - $a) * $t;
} 

// String functions:

// Cast string to number
// Credit: Hugo Giraudel | https://www.sassmeister.com/gist/9fa19d254864f33d4a80
@function number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
        }

    @if $character == '.' {
      $digits: 1; 
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);  
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }

  @return if($minus, -$result, $result);;
}

// Explode a string by a delimiter
// Credit: https://gist.github.com/danielpchen/3677421ea15dcf2579ff
@function explode($string, $delimiter) {
  $result: ();
  @if $delimiter == "" {
    @for $i from 1 through str-length($string) {
      $result: append($result, str-slice($string, $i, $i));
    }
    @return $result;
  }
  $exploding: true;
  @while $exploding {
    $d-index: str-index($string, $delimiter);
    @if $d-index {
      @if $d-index > 1 {
        $result: append($result, str-slice($string, 1, $d-index - 1));
        $string: str-slice($string, $d-index + str-length($delimiter));
      } @else if $d-index == 1 {
        $string: str-slice($string, 1, $d-index + str-length($delimiter));
      } @else {
        $result: append($result, $string);
        $exploding: false;
      }
    } @else {
      $result: append($result, $string);
      $exploding: false;
    }
  }
  @return $result;
}

/// EXAMPLES

.classic-linear {
  @include interpolate('font-size', 600px, 12px, 900px, 24px); 
}
.easy-peasy {
  @include interpolate('font-size', 600px, 12px, 900px, 24px, 'ease-in');
}
.cubic-bezier {
  @include interpolate('font-size', 600px, 12px, 900px, 24px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}
.bloat-my-css {
  @include interpolate('font-size', 600px, 12px, 900px, 24px, 'ease-in-ease-out', 6); 
}



// ------------------------------------------------------------------- //
// Type Utilities
// ------------------------------------------------------------------- //

// Font Mixin
@mixin font($weight: false,  $lh: false) {
    font-family: $family;
    @if $weight { font-weight: $weight; }
    @if $lh     { line-height: $lh;     }
}

// Font Size
.u-f1 { @include blend(font-size, $sm, $xl, 32px, 40px); }
.u-f2 { @include blend(font-size, $sm, $xl, 28px, 32px); }
.u-f3 { @include blend(font-size, $sm, $xl, 24px, 28px); }
.u-f4 { @include blend(font-size, $sm, $xl, 20px, 24px); }
.u-f5 { @include blend(font-size, $md, $xl, 18px, 20px); }
.u-f6 { @include blend(font-size, $sm, $xl, 15px, 18px); }

.u-f-lg { @include blend(font-size, $sm, $xl, 32px, 40px); }
.u-f-md { @include blend(font-size, $sm, $xl, 32px, 40px); }
.u-f-sm { @include blend(font-size, $sm, $xl, 13px, 16px); }

.w-20 {

}

// Before & After
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}


.ease {
  transition: all 0.3s ease 0s;
}


@mixin backgroundShorthand($imgpath,$position:0 0,$repeat: no-repeat) {
    background: transparent url(#{$imgpath}) $repeat $position;
}

.u-uppercase {
    @extend .u-f-sm;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.7px;
}


.u-download-icon {
  position: relative;
  top: 5px;
  right: 5px;
  background: $secondary;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  text-indent: -9999px;
  display: inline-block;
  &:after {
    position: absolute;
    top: 50%;
    left:  calc(50% - 1px);
    content: '';
    display: block;
    height: 1.5px;
    width: 7px;
    background: white;
    transform: rotate(135deg);
  }
  &:before {
    position: absolute;
    top: 50%;
    right: calc(50% - 2px);
    content: '';
    display: block;
    height: 1.5px;
    width: 7px;
    background: white;
    transform: rotate(45deg);
  }
}


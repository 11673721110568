
// ------------------------------------------------------------------- //
// Fixes:
// ------------------------------------------------------------------- //


.card {
	&.form {
		@include interpolate('padding', $xs, 20px, $lg, 30px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
		max-width: 550px;
		margin: 0 auto;
	}
}


.sign-up-single {
	padding: 0px;
}


.trigger-forgot {
	font-size: 15px;
}

// Login form link size
.trigger-forgot p, .trigger-forgot a, .trigger-forgot {
	@include interpolate('font-size', $xs, 13px, $lg, 15px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

#login-form a, footer .pages ul li, .story .btn {
	@include interpolate('font-size', $xs, 13px, $lg, 15px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}


.home .profile img {
	@include interpolate('max-width', $xs, 100px, $lg, 200px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
	@include interpolate('max-height', $xs, 100px, $lg, 200px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

.features .profile img {
	@include interpolate('max-width', $xs, 150px, $lg, 400px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
	@include interpolate('max-height', $xs, 150px, $lg, 400px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

section {
	.home & {
		width: calc(100% - 0px);
    	overflow-x: hidden;
	}
    
	&.features {
		@include interpolate('padding-top', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
		@include interpolate('padding-bottom', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
	}
	&.pricing {
		@include interpolate('padding-top', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
		@include interpolate('padding-bottom', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
	}
	&.story {
		@include interpolate('padding-top', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
		@include interpolate('padding-bottom', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
	}
}

.why-us .profile, .home .profile {
	@include interpolate('padding-bottom', $xs, 30px, $lg, 60px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

.profile h3 {
    @include interpolate('padding-top', $xs, 10px, $lg, 30px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

.profile h1 {
	@include interpolate('font-size', $xs, 36px, $lg, 72px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

h2 {
	@include interpolate('font-size', $xs, 21px, $lg, 32px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

h2.lead, .profiles h2.lead {
	@include interpolate('font-size', $xs, 28px, $lg, 48px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

.item h2.lead  {
	padding-top: 20px;
	margin-top: 0px;
}

.profiles {
	@include interpolate('padding-left', $md, 10px, $lg, 100px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');	
	@include interpolate('padding-right', $md, 10px, $lg, 100px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');	
}


.home p.lead {
	@include interpolate('font-size', $xs, 16px, $lg, 24px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
}

.home-hero {
	.container-fluid {
		margin: 0px;
		padding: 0px;
	}
}


.feature-hero h2.large {
	@include interpolate('font-size', $xs, 36px, $lg, 64px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');
	line-height: 1;
}

.pricing h2.hero {
	@include interpolate('font-size', $xs, 36px, $lg, 64px, 'cubic-bezier(0.755, 0.05, 0.855, 0.06)');	
}



@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 900px) 
  and (orientation: landscape)  { 

  	.owl-carousel {
  		margin-top:80px;
  		padding-bottom:50px;
  	}

  	.owl-dots {
  		bottom:-40px !important;
  		padding-bottom:50px;
  	}

  	.ill p.hero {
  		padding-left:5vw;
  		padding-right:5vw;
  	}
}

.sign-up {
	@include media-breakpoint-down(lg) {
		padding-top:60px;
	}
}

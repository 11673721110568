

#login-form {
	text-align: left;
	margin-top: 30px;
	input:not([type="checkbox"]), textarea {
		font-size: 18px;
		color: $grey;
		font-family: $reg;	
		background: none;
		padding: 15px 0px 6px 0px;
		border-bottom: solid 1px lighten($grey,35);
		width: 100%;
		margin-bottom: 12px;
		outline: none;
		&:active, &:focus {
			outline: none;
			border-bottom: solid 2px $primary;
			padding: 15px 0px 5px 0px;
		}
	&::placeholder {
		color: $primary;
	} 
	&.btn {
		@extend %btn-secondary;

		display: inline-block;
		width: 180px;

			&:hover, &:focus {
				background: $highlight;
			}
			.primary & {
				background: $primary;
				color: $primary;
			}
		}
	}
	a { 
		// color: $primary;
		border-bottom: none;
		font-size: 14px;
	}	
}

.help-block {
	font-size: 14px !important;
	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 10px;
	color: darken($primary, 20%);
	&.with-errors {
		color: red;
	}
}

#forgot {
	display: none;
}

.trigger-forgot, .member {
	color: white !important;
	font-size: 16px;
	a {
		color: $highlight !important;
		padding-bottom: 5px;
		border-bottom: solid 1px $highlight !important;
		font-family: $medium;
	}
}

.form-title {
	padding: 0px 0px 30px 0px;
	font-size: 28px;
}

.form-error {
	font-size: 12px;
	color: red;
}

.form {
	.btn {
		margin-top: 30px;
	}
}

.sign-up-single {
	// margin-left:10vw;
	// margin-right:10vw;
	// margin-top:8vw;
	// margin-bottom:8vw
	@include phone {
		
	}
}
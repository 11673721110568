// // Fonts need to be replaced with licensed versions

@font-face {
	font-family: "gt-walsheim";
	font-weight: normal;
	font-style: normal;
	src:url("../fonts/gt-walsheim/gt-walsheim-web.woff") format("woff"),
		url("../fonts/gt-walsheim/gt-walsheim-web.svg") format("svg"),
		url("../fonts/gt-walsheim/gt-walsheim-web.eot") format("eot"),
		url("../fonts/gt-walsheim/gt-walsheim-web.ttf") format("truetype");
}

@font-face {
	font-family: "gt-walsheim-light";
	font-weight: normal;
	font-style: normal;
	src:url("../fonts/gt-walsheim/gt-walsheim-light-web.woff") format("woff"),
		url("../fonts/gt-walsheim/gt-walsheim-light-web.svg") format("svg"),
		url("../fonts/gt-walsheim/gt-walsheim-light-web.eot") format("eot"),
		url("../fonts/gt-walsheim/gt-walsheim-light-web.ttf") format("truetype");
}

@font-face {
	font-family: "gt-walsheim-medium";
	font-weight: normal;
	font-style: normal;
	src:url("../fonts/gt-walsheim/gt-walsheim-medium-web.woff") format("woff"),
		url("../fonts/gt-walsheim/gt-walsheim-medium-web.svg") format("svg"),
		url("../fonts/gt-walsheim/gt-walsheim-medium-web.eot") format("eot"),
		url("../fonts/gt-walsheim/gt-walsheim-medium-web.ttf") format("truetype");
}

@font-face {
	font-family: "gt-walsheim-bold";
	font-weight: normal;
	font-style: normal;
	src:url("../fonts/gt-walsheim/gt-walsheim-bold-web.woff") format("woff"),
		url("../fonts/gt-walsheim/gt-walsheim-bold-web.svg") format("svg"),
		url("../fonts/gt-walsheim/gt-walsheim-bold-web.eot") format("eot"),
		url("../fonts/gt-walsheim/gt-walsheim-bold-web.ttf") format("truetype");
}

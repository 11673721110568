// ------------------------------------------------------------------- //
// Post Reset
// ------------------------------------------------------------------- //

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; 
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body { 
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } 
}

table {
  border-collapse: collapse;
  border-spacing: 0; 
}

a {
	text-decoration: none;
	color: blue; }


.container-fluid {
	// margin: 0;
	// padding: 0;
}
// Colors
// --------------------------------------------------
// Base Colors

$primary: #3462F5;
$highlight: #FEF86D;

$hover: #1c33f0;
$green: #91eb00;
$grey: #888;
$pink: #da51e5;
	
// Typography
// --------------------------------------------------

$headline: "gt-walsheim-medium", "Helvetica Neue", Helvetica, Arial, sans-serif;

$reg: "gt-walsheim", "Helvetica Neue", Helvetica, Arial, sans-serif;
$light: "gt-walsheim-light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$medium: "gt-walsheim-medium", "Helvetica Neue", Helvetica, Arial, sans-serif;


$primary-rgba: rgba(52, 98, 245, 1);
$radius: 3px 3px 3px 3px;

$grad: linear-gradient(180deg, rgba(84, 38, 255, 1) -100%, rgba(62, 63, 249, 1) 25%, rgba(17, 112, 237, 1) 75%, rgba(0, 131, 232, 1) 100%);
 
$shadow:  0px 0px 2px rgba(0, 0, 0, 0.24);
 
 



// Heights;

// Animation


$ease: cubic-bezier(0.86, 0, 0.07, 1);
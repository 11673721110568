%h-centered {
	position: relative;
	top: 48%;
	transform: translateY(-50%); 
}


%bg-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}


%icon {
	display: block;
	text-indent: 9999px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50%;
	overflow: hidden;
	border: solid 5px transparent;
}


%btn {
	font: 15px/1.2 $medium;
	@include prop(font-size, 14px, 14px, 14px, 14px, 14px);
	color: $primary;
	background: $highlight;
	padding: 15px 28px 16px 28px;
	text-transform: uppercase;
	letter-spacing: 1.2px;
	border-radius: $radius;
	box-shadow: $shadow;
	transition: all 0.2s ease-out;
	
	&:hover, &:focus {
		background: $hover;
		color: $highlight;
		box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
		border-bottom: solid 1px $primary;
	}
}

%btn-secondary {
	@extend %btn;
	color: $highlight;
	background: $primary;
	&:hover, &:focus {
		background: $hover;
		color: $highlight;
		box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
	}
}

%header {
	font: 21px/1.2 $medium;
	color: $highlight;
}



%spacer {
	@include prop(padding-top, 10px, 20px, 20px, 20px, 20px);
	@include prop(padding-right, 0px, 0px, 0px, 0px, 0px);
	@include prop(padding-bottom, 10px, 20px, 20px, 20px, 20px);
	@include prop(padding-left, 0px, 0px, 0px, 0px, 0px);
}



// ------------------------------------------------------------------- //
// Break-points
// ------------------------------------------------------------------- //

@mixin phone {
  @media (max-width: 574px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 575px) and (max-width: 767px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    @content;
  }
}

@mixin super {
  @media only screen and (min-width: 1401px) {
    @content;
  }
}

// ------------------------------------------------------------------- //
// Dynamic Property Mixin
// ------------------------------------------------------------------- //

@mixin prop($property, $phoneval, $tabletval, $laptopval, $deskval, $superval) {
  @include phone {
    #{$property}: #{$phoneval};
  }

  @include tablet {
    #{$property}: #{$tabletval};
  }

  @include laptop  {
    #{$property}: #{$laptopval};
  }

  @include desktop  {
    #{$property}: #{$deskval};
  }

  @include super {
    #{$property}: #{$superval};
  }
}


// ------------------------------------------------------------------- //
// Make item visible 
// ------------------------------------------------------------------- //

@mixin toggleVisbility($class) {
  #{$class} {
    display: none;
    pointer-events: none;
    &.active {
      display: block;
      pointer-events: inherit;
    }
  }
}

@mixin toggleOpacity($class) {
  #{$class} {
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: inherit;
    }
  }
}


// ------------------------------------------------------------------- //
// Round Images
// ------------------------------------------------------------------- //

@mixin roundImage($height, $width, $radius) {
  height: $height;
  width: $width;
  border-radius: $radius; 
}

// ------------------------------------------------------------------- //
// Break Points Tool
// ------------------------------------------------------------------- //


.breakpoints {
	height: 32px;
	width: 200px;
	position: fixed;
	bottom: 0px;
	color: black;
	opacity: 0.5;
	font-size: 12px;

	&:after {
		position: relative;
		top: 12px;
		left: 10px;
	}

  @include phone {
	background: green;
  	&:after {
  		content: 'Phone: 0 -> 575px';
  	}
  }

  @include tablet {
	background: orange;
	&:after {
		content: 'Tablet: 576px – 767px';
	}
  }

  @include laptop  {
	background: red;
	&:after {
		content: 'Laptop: 768px - 991px';
	}
  }

  @include desktop  {
	background: aqua;
	&:after {
		content: 'Desktop: 992px - 1400px';
	}
  }

  @include super {
	background: yellow;
	&:after {
		content: 'Super: 1441px';
	}
  }

}

@include phone {
  .hide-mobile {
    display: none !important;
  }
}

.hidden {
  display: none;
}

.show-mobile {
  display: none;
  @include phone {
    display: block;
  }
}

.show-nav-mobile {
  display:none;
  @media only screen and (min-width: 0px) and (max-width: 873px) {
    display:block;
  }
}

.centered {
  text-align: center;
}

.text-left {
  text-align: left;
}


// ------------------------------------------------------------------- //
// Body
// ------------------------------------------------------------------- //

body {
	color: $white;
	font-family: $reg;
	// background: $grad;
	background: #2F4FF5;
}

.feature-hero {
	// background: $grad;
}

.lighten-bg {
	background: rgba(255,255,255,0.1);
}



// ------------------------------------------------------------------- //
// Header
// ------------------------------------------------------------------- //

header {
	@include prop(padding-top, 30px, 20px, 20px, 20px, 40px);
	@include prop(padding-right, 30px, 20px, 20px, 20px, 40px);
	@include prop(padding-bottom, 30px, 20px, 20px, 20px, 40px);
	@include prop(padding-left, 30px, 20px, 20px, 20px, 40px);
	transition: all $ease .4s;

	&.bar {
		background: darken($primary, 35%);
		@include prop(padding-top, 20px, 15px, 15px, 15px, 15px);
		@include prop(padding-bottom, 20px, 15px, 15px, 15px, 15px);
		.logo, .navigation a {
			// color: $primary;
		}
	}
}


.logo {
	@extend %icon;
	background-image: url('/assets/images/logo@2x.png');
	color: white;
	border: none;
	background-position: 0%;
	text-indent: 60px;
	font-size: 21px;
	line-height: 40px;
	&:hover {
		border: none;
	}
	@include phone {
		text-indent: 9999px;
		overflow: hidden;
	}
}




// ------------------------------------------------------------------- //
// Menu
// ------------------------------------------------------------------- //


// ------------------------------------------------------------------- //
// Links
// ------------------------------------------------------------------- //

a {
	color: $highlight;
	transition: all 0.2s ease-out;
	border-bottom: solid 1px $highlight;
	padding-bottom: 6px;
	.navigation & {
		color: white;
		border-bottom: solid 1px transparent;
		padding-bottom: 0px;
		&:hover {
			border-bottom: solid 1px $highlight;
			padding-bottom: 5px;
		}
	}
	
	.is-active & {
			border-bottom: solid 1px $highlight;
			padding-bottom: 5px;
	} 

	footer & {
		color: white;
		border: 0px;
	}
}

.btn {
	@extend %btn;
	&.secondary {
		@extend %btn-secondary;
		display: inline-block;
		text-align: center;
		margin-top: 20px;
		width: 180px;
		@include laptop {
			width: 145px;
		}
		border-bottom: $primary;
		&:hover, &:focus {
			background: darken($primary, 5);
		}
	}
	.story & {
		margin-top: 10px;
		display: inline-block;
	}
}

.button-hint {
    margin: 10px 0;
    font-size: 12px;
    color: $grey;
}

// ------------------------------------------------------------------- //
// Typograpy
// ------------------------------------------------------------------- //

h1,h2,h3,h4,h5,h6 {
	@extend %header;
	.card & {
		color: $primary;
	}
}


h2 {
	@include prop(font-size, 32px, 32px, 32px, 36px, 36px);	
	.hero & {
		padding-top: 30px;
		@include prop(font-size, 32px, 32px, 32px, 42px, 48px);
		@include phone {
			padding-top: 0px;
		}
	}
	&.hero-title {
		@include prop(font-size, 64px, 10vw, 8vw, 100px, 100px);
		@include phone {
			display: none;
		}
	}
	.feature-title & {
		padding-bottom: 30px;
	}
	&.lead {
		@include prop(font-size, 32px, 32px, 32px, 42px, 48px);
		margin-top: 20px;
	}
	span.icon {
		font-size: 21px;
		display: inline-block;
		top: -5px;
		position: relative;
		margin-right: 20px !important;
		color: white;
	}
}

.content h2:not(:first-child) {
	@include prop(font-size, 24px, 24px, 24px, 28px, 28px);	
}

.home h2.lead {

}

.feature-hero {
	h2.large {
		@include prop(font-size, 32px, 32px, 32px, 42px, 72px);
	}
}

h3 {
	@include prop(font-size, 15px, 18px, 21px, 21px, 24px);
	padding-top: 30px;
	.card & {
		color: $primary;
	}

}



p {
	line-height: 1.4;
	@extend %spacer;
	@include prop(font-size, 15px, 16px, 18px, 21px, 21px);
	&.lead {
		@include prop(font-size, 18px, 21px, 21px, 24px, 24px);
	}
	&.hero {
		@include prop(font-size, 18px, 21px, 21px, 24px, 24px);
		@include prop(padding-top, 10px, 10px, 10px, 10px, 20px);
		@include prop(padding-bottom, 30px, 30px, 30px, 30px, 40px);
	}

	.card & {
		color: $grey;
	}
}

.home {
	p.lead {
		@include prop(font-size, 16px, 16px, 16px, 18px, 24px);
	}
}

.small p {
	@include prop(font-size, 15px, 16px, 16px, 16px, 16px);
}

ul {
	li {
		line-height: 2;
		nav.navigation & {
			@include prop(font-size, 14px, 15px, 15px, 16px, 18px);
		}
	}
}

.feature-text {
	ul {
		li {
			@include prop(font-size, 15px, 18px, 18px, 21px, 24px);
			line-height:1.7;
		}
	}
}

.card {
	color: $primary;
    a {
	    color: $primary;
	    border-bottom-color: $primary;
    }
}

.percentage {
	h4 {
		font-size: 56px;
		font-size: calc(1vw + 35px);
		position: relative;
		white-space: nowrap;

		span {
			font-size: 21px;
			color: $pink;
			position: absolute;
			top: 0px;
		}
	}
}

.small {
	font-size: 14px;
	padding-top: 0px;
}


.story {
	h2.lead {
		@include prop(font-size, 72px, 72px, 72px, 72px, 72px);
	}
}

.profiles {
	h2.lead {
		margin-top: 0px;
		@include prop(font-size, 32px, 42px, 32px, 48px, 52px);
	}
}



h1 {
	@include prop(font-size, 72px, 72px, 80px, 90px, 90px);
}

article {

	line-height: 1.4;

	h1 {
		@include prop(font-size, 36px, 36px, 50px, 60px, 60px);
	}

	ol, ul {
		list-style-type: decimal;
		padding: 20px;

		li {
			padding-bottom: 5px;
		}
	}

	p {
		font-size: 20px;
		line-height:28px;
	}
}

// ------------------------------------------------------------------- //
// Images
// ------------------------------------------------------------------- //

.content {
	ul {
		@extend %spacer;
	}
	
	img {
		@extend %spacer;
		&.rounded {
			border-radius: 100%;
		}
	}
}

.feature {
	.icon {
		background-color: rgba(255,255,255,0.1);
		padding: 18px 20px;
		border-radius: $radius;
	}
	.card {
		background-color: white;
		border-radius: $radius;
		box-shadow: $shadow;
		padding: 0 32px 16px 32px;
		.icon {
			color: $pink;
			float: left;
			margin: 16px 0 0 -68px ;
		}
		@include laptop {
			margin-bottom: 30px;
		}
	}
}

.card {
	&.indent {
		padding: 0 32px 16px 80px;
	}
}

.icon.sq-bg {
	background-color: rgba(255,255,255,0.1);
	padding: 18px 20px;
	border-radius: $radius;
	display: inline-block;
	    margin: 0px 0px 20px 0;

}


.card {
	background-color: white;
	padding: 32px;
	border-radius: $radius;
	box-shadow: $shadow;

	@media only screen and (min-width:426px) and (max-width: 767px) {
		padding:16px 25px;
	}

	@media only screen and (min-width:0px) and (max-width: 425px) { 
		padding:16px 15px;
	}
}



// ------------------------------------------------------------------- //
// Page Elements
// ------------------------------------------------------------------- //

#hero, .hero {
	text-align: center;
}

#hero {
	min-height: 100vh;
}

.feature-hero {
	.db-shadow {
		// box-shadow : 0px 10px 20px rgba(1, 42, 114, 0.4);
	}
}

// ------------------------------------------------------------------- //
// Slider
// ------------------------------------------------------------------- //

.owl-dot {
	border: solid 2px white;
	border-radius: 12px;
	&.active {
		background: rgba(255,255,255, 0.5)
	}
}


footer {
	&.sign-up {
		display: none !important;		
	}
}


.hero-title {
	
	span {
		transition: all $ease .4s;
		opacity: 1;
		display: inline-block;
	}

	span.out {
		opacity: 0;
		transform: translateY(10px);
	}

}

.ill img {
	opacity: 1;
	transition: all $ease .8s;
}

.ill img.out {
	opacity: 0;
	transform: translateX(15px);	
}

#stories {

}




// ------------------------------------------------------------------- //
// Reuseables
// ------------------------------------------------------------------- //

.no-padding {
	padding:0 !important; 
}

.early-access {
	margin: 60px 0;
}

.dev-form {
	background-color: #3462F5;
}

.features footer {
	// background: #3462F5;
}



.feature-img {
	padding:0;
	
	.bg-image {
		height: 400px;
		width: 100%;
		@include prop(width, 100%, 100%, 100%, 100%, 100%);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		@media only screen and (max-width: 991px) {
			height:300px !important;
			margin:0;
			background-position: center center;
		}

		@include phone {
			margin: 0px 0px 10px 0;
		}

		@include phone {
			background-position:50% 100%;
		}
	}
}

.feature:last-of-type {
	.feature-img {
		.bg-image {
			@media only screen and (max-width: 991px) {
				height:320px;
			}	
		}
	}
}

.text-center {
	text-align: center;
}


.feature-text {
	// @include prop(padding, 0 30px, 0 30px, 0 50px, 0 80px, 0 80px);

	ul li {
		&::before {
			content: "• ";
			color: $highlight;
			display: inline-block;
			margin-right: 10px; 
		}
	}

	.content {
		// border:3px solid red;
	}
}

.display {
	// @media only screen and (min-width: 0px) and (max-width: 767px) {
	// 	flex-direction:column !important;
	// }
}	

footer a {
	@include phone {
		font-size: 12px;
	}
}


// ------------------------------------------------------------------- //
// PRIVACY
// ------------------------------------------------------------------- //

.privacy-content {
	width:800px;

	@media only screen and (min-width: 768px) and (max-width: 1023px) {
		width: 80vw;
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
	}

	p {
		font-size:18px;

		@media only screen and (min-width: 768px) and (max-width: 1023px) {
			font-size:16px;
		}

		@media only screen and (max-width: 767px) {
			font-size:16px;
		}
	}

	strong {
		font-weight: bold;
	}

	hr {

	}
}


// ------------------------------------------------------------------- //
// CONTACT
// ------------------------------------------------------------------- //

.thankyou {
	font-size: 24px;
	text-align:center;
}



// ------------------------------------------------------------------- //
// Footer
// ------------------------------------------------------------------- //

footer {
	@include phone {

		.pages {
			ul {
				text-align:center;
				li:first-of-type {
					// background:red;
					display:block;
				}

				li {
					margin: 0 10px;
				}
			}
		}

		.social ul {
			text-align:center;
		}

	}

	@include tablet {
		.pages {
			ul {

				li {
					line-height:1.6 !important;
				}

			}
		}
	}
}


body.home {
	section.story.grad {
		@include phone {
			padding-bottom:0;
		}
	}
}



// ------------------------------------------------------------------- //
// BLOG
// ------------------------------------------------------------------- //

section.blog {

	article {
        background:white;

		.article-header {

			h2 {
				a {
					color: $blue;
					border-bottom:0;
					display:block;
				}
			}

			p {
				color:$blue;
			}
		}


		.blog-posts {
			p {
				color: $blue;
			}
		}

		.blog-content {

			h1, h2, h3, h4, h5, h6 {
                color: $primary;
                padding-top: 30px;
			}

			p, ol, ul {
				color: $blue;
			}

			em {
				font-style: italic;
			}

			strong {
				font-weight: bold;
			}

			a {
				color: blue;
				padding-bottom: 2px;
				border-bottom: none;
			}
						
		}

		.article-more {

			a.post {
				display:inline-block;
				color: $blue;
				border-bottom:1px solid $blue;
			}
		}

        blockquote {
            margin: 20px 0 0 20px;
            font-style: italic;
        }
	}
}

.single-post.blog {
    background:white;

    .article-title {
        text-align:center;

        h2 {
            color: $blue;
        }
	}

	// .blog-noimage {

	// 	.article-title {
	// 		text-align:center;
	// 	}
	// }

	.blog {
		article {
			.article-date {
                font-size: 14px;
			}
		}
	}
}

.pagination a.left {

}

nav.pagination {
	@include phone {
		text-align:center;
	}
}